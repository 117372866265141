import { App } from "@/constants";
import { BehaviorSubject, Observable, map } from "rxjs";
import { singleton } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import { APP_STATUS } from "./types";

type AppsStatuses = Record<App, APP_STATUS>;

@singleton()
class AppsStatusesProvider {
    private appsStatuses: BehaviorSubject<AppsStatuses>;

    constructor(private readonly logger: LoggerInterface) {
        this.logger.debug("AppsStatusesProvider is initialized");

        const appsStatuses: AppsStatuses = {} as AppsStatuses;
        Object.values(App).forEach((app) => {
            appsStatuses[app] = APP_STATUS.NOT_INITIALIZED;
        });
        this.appsStatuses = new BehaviorSubject<AppsStatuses>(appsStatuses);
    }

    selectAppStatus(app: App): Observable<APP_STATUS> {
        return this.appsStatuses.pipe(map((appsStatuses) => appsStatuses[app]));
    }

    updateAppStatus(app: App, status: APP_STATUS) {
        const appsStatuses = this.appsStatuses.value;
        appsStatuses[app] = status;
        this.appsStatuses.next(appsStatuses);
    }
}

export default AppsStatusesProvider;
