import { container } from "tsyringe";

import { ERROR_CODES, PROMISE_TIMEOUT_MS } from "./constants";
import { SdkEventInterface } from "./events/SdkEventInterface";
import SdkError from "./methods/SdkError";
import { SdkMethodPayloadInterface } from "./methods/SdkMethodPayloadInterface";
import { sdkMethodsCollectorToken, sdkToken } from "./tokens";

interface CustomSubscription {
    unsubscribe: () => void;
}

export function subscribeToSdkMethod<
    T extends SdkMethodPayloadInterface = never
>(
    methodName: T["methodName"],
    callback: (payload: T) => void
): CustomSubscription {
    const sdkMethodsCollector = container.resolve(sdkMethodsCollectorToken);
    sdkMethodsCollector.addSubscriber<T>(methodName, callback);

    return {
        unsubscribe() {
            sdkMethodsCollector.deleteSubscriber(methodName, callback);
        },
    };
}

export function checkSubscribers(
    methodName: string,
    reject: (error: SdkError) => void
) {
    const sdkMethodsCollector = container.resolve(sdkMethodsCollectorToken);
    if (sdkMethodsCollector.hasSubscribers(methodName)) return;

    reject(
        new SdkError(
            ERROR_CODES.NO_SUBSCRIBERS,
            "Method execution failed: no subscribers found"
        )
    );
}

export function dispatchSdkEvent(event: SdkEventInterface) {
    if (container.isRegistered(sdkToken)) {
        const sdk = container.resolve(sdkToken);
        sdk.dispatch(event);
    }
}

export function promiseWithTimeout<T>(
    promise: Promise<T>,
    timeoutMs: number = PROMISE_TIMEOUT_MS
) {
    let timeout: ReturnType<typeof setTimeout>;

    const timeoutPromise = new Promise<Error>((resolve, reject) => {
        timeout = setTimeout(() => {
            reject(
                new SdkError(
                    ERROR_CODES.TIMEOUT_ERROR,
                    `The operation did not complete within ${
                        timeoutMs / 1000
                    } seconds. Please try again.`
                )
            );
        }, timeoutMs);
    });

    return Promise.race([promise, timeoutPromise])
        .then((result) => {
            clearTimeout(timeout);
            return result;
        })
        .catch((error) => {
            clearTimeout(timeout);
            throw error;
        });
}

export function checkRequiredArguments(
    methodName: string,
    requiredCount: number,
    presentCount: number,
    reject: (error: SdkError) => void
) {
    if (presentCount < requiredCount) {
        reject(
            new SdkError(
                ERROR_CODES.INSUFFICIENT_ARGS,
                `Failed to execute '${methodName}' on 'sdk': ${requiredCount} arguments required, but only ${presentCount} present.`
            )
        );
    }
}
