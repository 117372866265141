import Timer from "@lib/timer";
import AnalyticsEventsCollectorInterface from "@modules/analytics_events_collector/AnalyticsEventsCollector/AnalyticsEventsCollectorInterface";
import { AnalyticsEventsFactory } from "@modules/analytics_events_collector/AnalyticsEventsCollector/AnalyticsEventsFactory";
import LocationControllerInterface from "@modules/common_module/LocationController/LocationControllerInterfce";
import { Observable, combineLatest, of } from "rxjs";
import { map } from "rxjs/operators";

import ApiClientInterface from "./ApiClientInterface";
import ApiClientRequest from "./ApiClientRequest";
import { ApiClientResponse } from ".";

class ApiClientWithMetrics implements ApiClientInterface {
    constructor(
        private readonly apiClient: ApiClientInterface,
        private readonly eventsCollector: AnalyticsEventsCollectorInterface,
        private readonly locationController: LocationControllerInterface,
        private readonly analyticsEventsFactory: AnalyticsEventsFactory
    ) {}

    public getPathname(reqConfigUrl: string) {
        try {
            return new URL(reqConfigUrl).pathname;
        } catch (e: unknown) {
            return new URL(reqConfigUrl, this.locationController.getOrigin()).pathname;
        }
    }

    get(reqConfig: Omit<ApiClientRequest, "method" | "body">): Observable<ApiClientResponse<unknown>> {
        return combineLatest([
            this.apiClient.get(reqConfig),
            of(
                (() => {
                    return new Timer();
                })()
            ),
        ]).pipe(
            map(([requestResult, timer]) => {
                this.eventsCollector.pushEvent(
                    this.analyticsEventsFactory.createDataLoaded(this.getPathname(reqConfig.url), timer.ready())
                );
                return requestResult;
            })
        );
    }
    delete(reqConfig: Omit<ApiClientRequest, "method" | "body">): Observable<ApiClientResponse<unknown>> {
        return combineLatest([
            this.apiClient.delete(reqConfig),
            of(
                (() => {
                    return new Timer();
                })()
            ),
        ]).pipe(
            map(([requestResult, timer]) => {
                this.eventsCollector.pushEvent(
                    this.analyticsEventsFactory.createDataLoaded(this.getPathname(reqConfig.url), timer.ready())
                );
                return requestResult;
            })
        );
    }
    post(reqConfig: Omit<ApiClientRequest, "method">): Observable<ApiClientResponse<unknown>> {
        return combineLatest([
            this.apiClient.post(reqConfig),
            of(
                (() => {
                    return new Timer();
                })()
            ),
        ]).pipe(
            map(([requestResult, timer]) => {
                this.eventsCollector.pushEvent(
                    this.analyticsEventsFactory.createDataLoaded(this.getPathname(reqConfig.url), timer.ready())
                );
                return requestResult;
            })
        );
    }
    put(reqConfig: Omit<ApiClientRequest, "method">): Observable<ApiClientResponse<unknown>> {
        return combineLatest([
            this.apiClient.put(reqConfig),
            of(
                (() => {
                    return new Timer();
                })()
            ),
        ]).pipe(
            map(([requestResult, timer]) => {
                this.eventsCollector.pushEvent(
                    this.analyticsEventsFactory.createDataLoaded(this.getPathname(reqConfig.url), timer.ready())
                );
                return requestResult;
            })
        );
    }
    patch(reqConfig: Omit<ApiClientRequest, "method">): Observable<ApiClientResponse<unknown>> {
        return combineLatest([
            this.apiClient.patch(reqConfig),
            of(
                (() => {
                    return new Timer();
                })()
            ),
        ]).pipe(
            map(([requestResult, timer]) => {
                this.eventsCollector.pushEvent(
                    this.analyticsEventsFactory.createDataLoaded(this.getPathname(reqConfig.url), timer.ready())
                );
                return requestResult;
            })
        );
    }
}

export default ApiClientWithMetrics;
