import { AnalyticsEventsCollectorModule } from "@modules/analytics_events_collector/AnalyticsEventsCollectorModule";
import { inject, singleton } from "tsyringe";

import SdkAnalyticsEventsFactory from "./analytics/SdkAnalyticsEventsFactory";
import CommonSdk from "./common_sdk/CommonSdk";
import SdkEvent from "./events/SdkEvent";
import { SdkEventInterface } from "./events/SdkEventInterface";
import { SdkRegisteredModuleInterface } from "./SdkRegisteredModuleInterface";
import { SdkInitOptions } from "./types";

@singleton()
class Sdk {
    private readonly _emitter: EventTarget;

    private readonly _modules: Record<string, SdkRegisteredModuleInterface> =
        {};

    public readonly common?: CommonSdk;

    constructor(
        @inject(AnalyticsEventsCollectorModule)
        private readonly analyticsEventsCollectorModule: AnalyticsEventsCollectorModule,
        private readonly _analyticsEventsFactory: SdkAnalyticsEventsFactory,
        private readonly _initApp?: (options: SdkInitOptions) => void
    ) {
        this._emitter = new EventTarget();
    }

    public init(options: SdkInitOptions) {
        if (this._initApp) {
            this._initApp(options);
        }
    }

    public on(type: string, callback: (event: Event) => void) {
        this.analyticsEventsCollectorModule.exports.eventsCollector.pushEvent(
            this._analyticsEventsFactory.createEventSubscribed(type)
        );
        this._emitter.addEventListener(type, callback);
    }

    public off(type: string, callback: (event: Event) => void) {
        this.analyticsEventsCollectorModule.exports.eventsCollector.pushEvent(
            this._analyticsEventsFactory.createEventUnsubscribed(type)
        );
        this._emitter.removeEventListener(type, callback);
    }

    public dispatch(event: SdkEventInterface) {
        this.analyticsEventsCollectorModule.exports.eventsCollector.pushEvent(
            this._analyticsEventsFactory.createEventDispatched(event.type)
        );
        this._emitter.dispatchEvent(new SdkEvent(event.type, event));
    }

    public registerModule(
        moduleName: string,
        module: SdkRegisteredModuleInterface
    ) {
        module.register(this);
        this._modules[moduleName] = module;

        this.analyticsEventsCollectorModule.exports.eventsCollector.pushEvent(
            this._analyticsEventsFactory.createSdkModuleRegistered(moduleName)
        );
    }
}

export default Sdk;
