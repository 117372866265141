import "reflect-metadata";

import "./themes/default/default.css";
import "./themes/globals.css";

import {
    IloomCustomizationApplicationConfigInterface,
    IloomCustomizationApplicationConfigToken,
} from "@apps/iloom_customization/IloomCustomizationApplicationConfig";
import {
    InstagramApplicationConfigInterface,
    InstagramApplicationConfigToken,
} from "@apps/instagram/InstagramApplicationConfig";
import { ReviewsApplicationConfigInterface } from "@apps/reviews/ReviewsApplicationConfig";
import { ReviewsApplicationConfigToken } from "@apps/reviews/tokens";
import { RewardsWidgetsPlaceholders } from "@apps/rewards/constants/constants";
import {
    RewardsApplicationConfigInterface,
    RewardsApplicationConfigToken,
} from "@apps/rewards/RewardsApplicationConfig";
import {
    TikTokApplicationConfigInterface,
    TikTokApplicationConfigToken,
} from "@apps/tiktok/TikTokApplicationConfig";
import {
    WishlistApplicationConfigInterface,
    WishlistApplicationConfigToken,
} from "@apps/wishlist/WishlistApplicationConfig";
import CustomerInfoFromJsonProvider from "@lib/CustomerInfoProvider/CustomerInfoFromJsonProvider";
import CustomerInfoFromStaticProvider from "@lib/CustomerInfoProvider/CustomerInfoFromStaticProvider";
import FetchAndXHRPatcher from "@lib/FetchAndXHRPatcher/FetchAndXHRPatcher";
import { GraphQLApiClient } from "@lib/GraphQLApiClient/GraphQLApiClient";
import GrowaveConfigurationFromJsonProvider from "@lib/GrowaveConfigurationProvider/GrowaveConfigurationFromJsonProvider";
import GrowaveConfigurationFromStaticProvider from "@lib/GrowaveConfigurationProvider/GrowaveConfigurationFromStaticProvider";
import HistoryPatcher from "@lib/HistoryPatcher";
import { LegacyAdapter } from "@lib/LegacyAdapter/LegacyAdapter";
import { SimpleLocalizationInfoProvider } from "@lib/LocalizationInfoProvider/SimpleLocalizationInfoProvider";
import { ProductIdFromAdditionalInfoProvider } from "@lib/ProductIdManager/ProductIdFromAdditionalInfoProvider";
import { ProductIdFromSdkProvider } from "@lib/ProductIdManager/ProductIdFromSdkProvider";
import { ProductIdManager } from "@lib/ProductIdManager/ProductIdManager";
import { ProductVariantIdFromAdditionalInfoProvider } from "@lib/ProductVariantIdManager/ProductVariantIdFromAdditionalInfoProvider";
import { ProductVariantIdFromSdkProvider } from "@lib/ProductVariantIdManager/ProductVariantIdFromSdkProvider";
import { ProductVariantIdFromUrlProvider } from "@lib/ProductVariantIdManager/ProductVariantIdFromUrlProvider";
import { ProductVariantsIdManager } from "@lib/ProductVariantIdManager/ProductVariantIdManager";
import StorefrontApiAccessTokenProvider from "@lib/StorefrontApiAccessTokenProvider/StorefrontApiAccessTokenProvider";
import Timer from "@lib/timer";
import { AnalyticsEventsCollectorModule } from "@modules/analytics_events_collector/AnalyticsEventsCollectorModule";
import { AuthenticationModule } from "@modules/authentication/AuthenticationModule";
import { CommonModule } from "@modules/common_module/CommonModule";
import { ProductsModule } from "@modules/products/ProductsModule";
import { SdkModule } from "@modules/sdk/SdkModule";
import { SdkInitOptions } from "@modules/sdk/types";
import { validate } from "superstruct";
import {
    DependencyContainer,
    container,
    instanceCachingFactory,
} from "tsyringe";

import { ModalManager } from "@components/Modal/ModalManager";

import { App } from "./constants";
import { CURRENT_APP_GROWAVE } from "./constants/current_app";
import { GP_GW_REFFERER, OSEID } from "./constants/get_params";
import InitOptionsEntity from "./entities/InitOptionsEntity";
import { MinilogLogger } from "./lib/Logger";
import InitOptionsSchema from "./schemas/InitOptionsSchema";
import {
    customerInfoProviderToken,
    designModeQueryParamsToken,
    fetchAndXhrPatcherToken,
    globalLoggerToken,
    globalProductIdManagerToken,
    globalProductVariantsIdManagerToken,
    growaveConfigurationProviderToken,
    growaveConfigurationToken,
    gwStorefrontAppInfoToken,
    isEmbeddedModeToken,
    localStorageToken,
    localizationInfoProviderToken,
    modalManagerToken,
    storefrontApiAccessTokenProviderToken,
} from "./tokens";
import checkIsEmbeddedMode from "./utils/checkIsEmbeddedMode";
import { getQueryParams } from "./utils/getQueryParams";

interface InitOptions {
    enabledApps: App[];
    customerId: number | null;
    customerEmail: string | null;
    isB2bCustomer: boolean;
    moneyFormat: string;
    moneyWithCurrencyFormat: string;
    shopCurrency: string;
    storefrontApiAccessToken: string | null;
    countryIsoCode: string;
    languageIsoCode: string;
    currencyIsoCode: string;
    gwI18nLocale: string;
}

// eslint-disable-next-line @typescript-eslint/require-await
async function init(options: InitOptions) {
    const globalLogger = container.resolve(globalLoggerToken);

    const [initOptionsError] = validate(options, InitOptionsSchema);

    if (initOptionsError) {
        globalLogger.error("Invalid init options", {
            initOptionsError,
            options,
        });
        throw new Error("Invalid init options");
    }

    container.register(localizationInfoProviderToken, {
        useFactory: instanceCachingFactory((di) => {
            return new SimpleLocalizationInfoProvider(
                di.resolve(growaveConfigurationToken).countryIsoCode,
                di.resolve(growaveConfigurationToken).languageIsoCode,
                di.resolve(growaveConfigurationToken).currencyIsoCode,
                di.resolve(growaveConfigurationToken).gwI18nLocale
            );
        }),
    });

    // (utils) GraphQLApiClient
    container.register(GraphQLApiClient, {
        useFactory: instanceCachingFactory((di) => {
            const storefrontApiAccessToken = di.resolve(
                growaveConfigurationToken
            ).storefrontApiAccessToken;
            if (storefrontApiAccessToken) {
                return new GraphQLApiClient(storefrontApiAccessToken);
            }
            return null;
        }),
    });

    container.resolve(AuthenticationModule);
    container.resolve(ProductsModule);

    container.resolve(LegacyAdapter);

    // ModalManager
    const modalManager = new ModalManager();
    container.registerInstance(modalManagerToken, modalManager);

    container
        .resolve(AnalyticsEventsCollectorModule)
        .exports.eventsCollector.pushEvent(
            container
                .resolve(AnalyticsEventsCollectorModule)
                .exports.eventsFactory.createChunkLoaded("main", -1)
        );

    if (options.enabledApps.includes(App.Instagram)) {
        container.register<InstagramApplicationConfigInterface>(
            InstagramApplicationConfigToken,
            {
                useValue: {
                    placeholderSelector: ".gw-instagram-gallery-placeholder",
                },
            }
        );
        const startLoadingInstagramApplicationTimer = new Timer();
        void import(
            /*webpackChunkName: "InstagramApplication"*/ "@apps/instagram/InstagramApplication"
        ).then(({ InstagramApplication }) => {
            const duration = startLoadingInstagramApplicationTimer.ready();
            container
                .resolve(AnalyticsEventsCollectorModule)
                .exports.eventsCollector.pushEvent(
                    container
                        .resolve(AnalyticsEventsCollectorModule)
                        .exports.eventsFactory.createChunkLoaded(
                            "InstagramApplication",
                            duration
                        )
                );
            const instagramApplication =
                container.resolve(InstagramApplication);
            instagramApplication.init();
        });
    }

    if (options.enabledApps.includes(App.Login)) {
        const startLoadingLoginApplicationTimer = new Timer();
        void import(
            /*webpackChunkName: "LoginApplication"*/ "@apps/login/LoginApplication"
        ).then((/*{ LoginApplication }*/) => {
            const duration = startLoadingLoginApplicationTimer.ready();
            container
                .resolve(AnalyticsEventsCollectorModule)
                .exports.eventsCollector.pushEvent(
                    container
                        .resolve(AnalyticsEventsCollectorModule)
                        .exports.eventsFactory.createChunkLoaded(
                            "LoginApplication",
                            duration
                        )
                );
            //Temporarily disabling the widget

            // const loginApplication = container.resolve(LoginApplication);
            // loginApplication.init();
        });
    }

    if (options.enabledApps.includes(App.TikTok)) {
        container.register<TikTokApplicationConfigInterface>(
            TikTokApplicationConfigToken,
            {
                useValue: {
                    placeholderSelector: "#gw-tiktok-gallery-placeholder",
                },
            }
        );
        const startLoadingTikTokApplicationTimer = new Timer();
        void import(
            /*webpackChunkName: "TikTokApplication" */ "@apps/tiktok/TikTokApplication"
        ).then(({ TikTokApplication }) => {
            const duration = startLoadingTikTokApplicationTimer.ready();
            container
                .resolve(AnalyticsEventsCollectorModule)
                .exports.eventsCollector.pushEvent(
                    container
                        .resolve(AnalyticsEventsCollectorModule)
                        .exports.eventsFactory.createChunkLoaded(
                            "TikTokApplication",
                            duration
                        )
                );
            const tiktokApplication = container.resolve(TikTokApplication);
            tiktokApplication.init();
        });
    }

    if (options.enabledApps.includes(App.Wishlist)) {
        container.register<WishlistApplicationConfigInterface>(
            WishlistApplicationConfigToken,
            {
                useValue: {
                    drawerWidgetPlaceholderSelector: "body",
                    addToWishlistProductPageButtonSelector:
                        ".gw-wl-add-to-wishlist-placeholder",
                    addToWishlistProductCardSelector:
                        ".gw-add-to-wishlist-product-card-placeholder",
                    goToWishlistPageHeaderWidgetInjector:
                        ".gw-wl-header-icon-placeholder",
                    wishlistPageWidgetPlaceholderSelector:
                        ".gw-wl-page-placeholder",
                    sharedWishlistPageWidgetPlaceholderSelector:
                        ".gw-wl-shared-page-placeholder",
                    saveWishlistModalWidgetPlaceholderSelector: "body",
                },
            }
        );

        const startLoadingWishlistApplicationTimer = new Timer();
        void import(
            /*webpackChunkName: "WishlistApplication" */ "@apps/wishlist/WishlistApplication"
        ).then(({ WishlistApplication }) => {
            const duration = startLoadingWishlistApplicationTimer.ready();
            container
                .resolve(AnalyticsEventsCollectorModule)
                .exports.eventsCollector.pushEvent(
                    container
                        .resolve(AnalyticsEventsCollectorModule)
                        .exports.eventsFactory.createChunkLoaded(
                            "WishlistApplication",
                            duration
                        )
                );
            const wishlistApplication = container.resolve(WishlistApplication);
            wishlistApplication.init();
        });
    }

    if (
        options.enabledApps.includes(App.Reviews) ||
        options.enabledApps.includes(App.Questions)
    ) {
        // reviews
        container.registerInstance<ReviewsApplicationConfigInterface>(
            ReviewsApplicationConfigToken,
            {
                drawerWidgetPlaceholderSelector: "body",
                averageWidgetPlaceholderSelector: ".gw-rv-average-placeholder",
                listingAverageReviewsWidgetPlaceholderSelector:
                    ".gw-rv-listing-average-placeholder",
                reviewsProductPageWidgetPlaceholderSelector:
                    ".gw-rv-main-widget-placeholder",
                reviewsPageWidgetPlaceholderSelector:
                    ".gw-rv-reviews-page-widget-placeholder",
                leaveReviewPageWidgetPlaceholderSelector:
                    ".gw-rv-leave-review-page-widget-placeholder",
                reviewsSliderWidgetPlaceholderSelector:
                    ".gw-rv-reviews-slider-widget-placeholder",
                reviewsGalleryWidgetPlaceholderSelector: "body",
                productReviewsMiniSliderWidgetPlaceholderSelector:
                    ".gw-rv-mini-slider-widget-placeholder",
                reviewsFormModalWidgetPlaceholderSelector: "body",
                questionsFormModalWidgetPlaceholderSelector: "body",
            }
        );

        const startLoadingReviewsApplicationTimer = new Timer();
        void import(
            /*webpackChunkName: "ReviewsApplication" */ "@apps/reviews/ReviewsApplication"
        ).then(({ ReviewsApplication }) => {
            const duration = startLoadingReviewsApplicationTimer.ready();
            container
                .resolve(AnalyticsEventsCollectorModule)
                .exports.eventsCollector.pushEvent(
                    container
                        .resolve(AnalyticsEventsCollectorModule)
                        .exports.eventsFactory.createChunkLoaded(
                            "ReviewsApplication",
                            duration
                        )
                );
            const reviewsApplication = container.resolve(ReviewsApplication);
            reviewsApplication.init();
        });
    }

    // eslint-disable-next-line no-constant-condition
    if (options.enabledApps.includes(App.IloomCustomization)) {
        container.register<IloomCustomizationApplicationConfigInterface>(
            IloomCustomizationApplicationConfigToken,
            {
                useValue: {
                    designFeedPageWidgetPlaceholderSelector:
                        ".gw-df-page-placeholder",
                    designFeedHomeWidgetPlaceholderSelector:
                        ".gw-df-home-placeholder",
                    designFeedProductWidgetPlaceholderSelector:
                        ".gw-df-product-placeholder",
                    wishlistPageWidgetPlaceholderSelector:
                        ".gw-cwl-page-placeholder",
                    reviewsPageWidgetPlaceholderSelector:
                        ".gw-crv-page-placeholder",
                    reviewsFormModalWidgetPlaceholderSelector:
                        ".gw-crv-form-modal-widget-placeholder",
                },
            }
        );

        const startLoadingIloomCustomizationApplicationTimer = new Timer();
        void import(
            /*webpackChunkName: "IloomCustomizationApplication" */ "@apps/iloom_customization/IloomCustomizationApplication"
        ).then(({ IloomCustomizationApplication }) => {
            const duration =
                startLoadingIloomCustomizationApplicationTimer.ready();
            container
                .resolve(AnalyticsEventsCollectorModule)
                .exports.eventsCollector.pushEvent(
                    container
                        .resolve(AnalyticsEventsCollectorModule)
                        .exports.eventsFactory.createChunkLoaded(
                            "IloomCustomizationApplication",
                            duration
                        )
                );
            const iloomCustomizationApplication = container.resolve(
                IloomCustomizationApplication
            );
            iloomCustomizationApplication.init();
        });
    }

    if (options.enabledApps.includes(App.Rewards)) {
        container.register<RewardsApplicationConfigInterface>(
            RewardsApplicationConfigToken,
            {
                useValue: {
                    rewardsPopUpWidgetWrapperPlaceholder:
                        RewardsWidgetsPlaceholders.rewardsPopUpWidgetWrapperPlaceholder,
                    rewardsPopUpRootWidgetPlaceholder:
                        RewardsWidgetsPlaceholders.rewardsPopUpRootWidgetPlaceholder,
                    rewardsJoinNowModalWidgetPlaceholder:
                        RewardsWidgetsPlaceholders.rewardsJoinNowModalWidgetPlaceholder,
                    rewardsVerifyAccountModalWidgetPlaceholder:
                        RewardsWidgetsPlaceholders.rewardsVerifyAccountModalWidgetPlaceholder,
                    rewardsWelcomeBannerPageWidgetPlaceholder:
                        RewardsWidgetsPlaceholders.rewardsWelcomeBannerPageWidgetPlaceholder,
                    rewardsHowItWorksPageWidgetPlaceholder:
                        RewardsWidgetsPlaceholders.rewardsHowItWorksPageWidgetPlaceholder,
                    rewardsWaysToEarnPageWidgetPlaceholder:
                        RewardsWidgetsPlaceholders.rewardsWaysToEarnPageWidgetPlaceholder,
                    rewardsWaysToEarnPageWidgetModalPlaceholder:
                        RewardsWidgetsPlaceholders.rewardsWaysToEarnPageWidgetModalPlaceholder,
                    rewardsWaysToSpendPageWidgetPlaceholder:
                        RewardsWidgetsPlaceholders.rewardsWaysToSpendPageWidgetPlaceholder,
                    rewardsWaysToSpendPageWidgetModalPlaceholder:
                        RewardsWidgetsPlaceholders.rewardsWaysToSpendPageWidgetModalPlaceholder,
                    rewardsCustomerRewardsModalPlaceholder:
                        RewardsWidgetsPlaceholders.rewardsCustomerRewardsModalPlaceholder,
                    rewardsVipTiersPageWidgetPlaceholder:
                        RewardsWidgetsPlaceholders.rewardsVipTiersPageWidgetPlaceholder,
                    rewardsReferralProgramPageWidgetPlaceholder:
                        RewardsWidgetsPlaceholders.rewardsReferralProgramPageWidgetPlaceholder,
                    rewardsEarnPointsProductPagePlaceholder:
                        RewardsWidgetsPlaceholders.rewardsEarnPointsProductPagePlaceholder,
                },
            }
        );

        const startLoadingRewardsApplicationTimer = new Timer();
        void import(
            /*webpackChunkName: "RewardsApplication"*/ "@apps/rewards/RewardsApplication"
        ).then(({ RewardsApplication }) => {
            const duration = startLoadingRewardsApplicationTimer.ready();
            container
                .resolve(AnalyticsEventsCollectorModule)
                .exports.eventsCollector.pushEvent(
                    container
                        .resolve(AnalyticsEventsCollectorModule)
                        .exports.eventsFactory.createChunkLoaded(
                            "RewardsApplication",
                            duration
                        )
                );
            const rewardsApplication = container.resolve(RewardsApplication);
            rewardsApplication.init();
        });
    }
}

async function initFromAdditionalInfo() {
    const globalLogger = container.resolve(globalLoggerToken);

    // (utils) customerInfoProvider
    const customerInfoProvider = new CustomerInfoFromJsonProvider(globalLogger);
    customerInfoProvider.readData();
    container.registerInstance(customerInfoProviderToken, customerInfoProvider);

    // (utils) growaveConfigurationProvider
    container.register(growaveConfigurationProviderToken, {
        useFactory: instanceCachingFactory(() => {
            return new GrowaveConfigurationFromJsonProvider(
                container.resolve(globalLoggerToken)
            );
        }),
    });

    container.register(growaveConfigurationToken, {
        useFactory: instanceCachingFactory((di: DependencyContainer) => {
            return di
                .resolve(growaveConfigurationProviderToken)
                .getConfiguration();
        }),
    });

    const initOptions: InitOptionsEntity = {
        enabledApps: container.resolve(growaveConfigurationToken).availableApps,
        customerId: customerInfoProvider.getId(),
        customerEmail: customerInfoProvider.getEmail(),
        isB2bCustomer: customerInfoProvider.getIsB2b(),
        moneyFormat: container.resolve(growaveConfigurationToken).moneyFormat,
        moneyWithCurrencyFormat: container.resolve(growaveConfigurationToken)
            .moneyWithCurrencyFormat,
        shopCurrency: container.resolve(growaveConfigurationToken).shopCurrency,
        storefrontApiAccessToken: container.resolve(growaveConfigurationToken)
            .storefrontApiAccessToken,
        countryIsoCode: container.resolve(growaveConfigurationToken)
            .countryIsoCode,
        languageIsoCode: container.resolve(growaveConfigurationToken)
            .languageIsoCode,
        currencyIsoCode: container.resolve(growaveConfigurationToken)
            .currencyIsoCode,
        gwI18nLocale: container.resolve(growaveConfigurationToken).gwI18nLocale,
    };

    await init(initOptions);
}

export function initFromSdk(options: SdkInitOptions) {
    const isEmbeddedMode = container.resolve(isEmbeddedModeToken);

    if (isEmbeddedMode) {
        throw new Error(
            "Initialization from the SDK is not supported in embedded mode"
        );
    }

    const globalLogger = container.resolve(globalLoggerToken);

    // (utils) customerInfoProvider
    const customerInfoProvider = new CustomerInfoFromStaticProvider(
        globalLogger,
        {
            id: null,
            email: null,
            isB2b: false,
        }
    );
    customerInfoProvider.readData();
    container.registerInstance(customerInfoProviderToken, customerInfoProvider);

    // (utils) storefrontApiAccessTokenProvider
    container.register(storefrontApiAccessTokenProviderToken, {
        useFactory: instanceCachingFactory((di) => {
            return new StorefrontApiAccessTokenProvider(
                di.resolve(globalLoggerToken)
            );
        }),
    });

    const storefrontApiAccessToken = container
        .resolve(storefrontApiAccessTokenProviderToken)
        .getToken();

    // (utils) growaveConfigurationProvider
    const growaveConfigurationProvider =
        new GrowaveConfigurationFromStaticProvider(globalLogger, {
            moneyFormat: options.moneyFormat || "{{amount}}",
            moneyWithCurrencyFormat:
                options.moneyWithCurrencyFormat || "{{amount}}",
            shopCurrency: options.shopCurrency || "USD",
            availableApps: options.enabledApps,
            storefrontApiAccessToken:
                storefrontApiAccessToken ||
                options.storefrontApiAccessToken ||
                null,
            countryIsoCode: options.countryIsoCode || "US",
            languageIsoCode: options.languageIsoCode || "EN",
            currencyIsoCode: options.currencyIsoCode || "USD",
            gwI18nLocale: options.localization || "en",
            myshopifyDomain:
                options.myshopifyDomain || (window.Shopify?.shop as string),
        });
    const growaveConfiguration =
        growaveConfigurationProvider.getConfiguration();
    container.registerInstance(
        growaveConfigurationProviderToken,
        growaveConfigurationProvider
    );
    container.registerInstance(growaveConfigurationToken, growaveConfiguration);

    const initOptions: InitOptionsEntity = {
        enabledApps: options.enabledApps,
        customerId: customerInfoProvider.getId(),
        customerEmail: customerInfoProvider.getEmail(),
        isB2bCustomer: customerInfoProvider.getIsB2b(),
        moneyFormat: growaveConfiguration.moneyFormat,
        moneyWithCurrencyFormat: growaveConfiguration.moneyWithCurrencyFormat,
        shopCurrency: growaveConfiguration.shopCurrency,
        storefrontApiAccessToken: growaveConfiguration.storefrontApiAccessToken,
        countryIsoCode: growaveConfiguration.countryIsoCode,
        languageIsoCode: growaveConfiguration.languageIsoCode,
        currencyIsoCode: growaveConfiguration.currencyIsoCode,
        gwI18nLocale: growaveConfiguration.gwI18nLocale,
    };

    void init(initOptions);
}

function __gwMain() {
    container.register(globalLoggerToken, {
        useFactory: instanceCachingFactory(() => {
            return new MinilogLogger("global");
        }),
    });
    container
        .resolve(globalLoggerToken)
        .info("Growave main script is initialized!");

    container.register(gwStorefrontAppInfoToken, {
        useFactory: instanceCachingFactory(() => {
            return {
                appName: __APP_NAME__,
                version: __VERSION__,
                currentApp: CURRENT_APP_GROWAVE,
                tokenStorageKey: container
                    .resolve(CommonModule)
                    .exports.designModeProvider.isDesignMode()
                    ? "GW_TOKEN__DESIGN_MODE"
                    : "GW_TOKEN",
                appProxyPrefix: "/apps/ssw",
            };
        }),
    });

    container.register(designModeQueryParamsToken, {
        useFactory: (): Record<string, string> => {
            if (window.Shopify?.designMode) {
                const urlParams = getQueryParams();
                if (urlParams[OSEID]) {
                    return {
                        [OSEID]: urlParams[OSEID],
                    };
                }
            }
            return {};
        },
    });

    container.register(localStorageToken, { useValue: localStorage });

    // (utils) historyPatcher
    const historyPatcher = new HistoryPatcher(
        [GP_GW_REFFERER],
        window.history,
        container.resolve(CommonModule).exports.locationController
    );
    historyPatcher.patch();

    // (utils) fetchAndXHRPatcher
    container.register(fetchAndXhrPatcherToken, {
        useFactory: instanceCachingFactory(() => {
            const fetchAndXHRPatcher = new FetchAndXHRPatcher(
                container.resolve(globalLoggerToken)
            );
            fetchAndXHRPatcher.patch();
            return fetchAndXHRPatcher;
        }),
    });
    container.resolve(fetchAndXhrPatcherToken);

    // Is embedded mode
    const isEmbeddedMode = checkIsEmbeddedMode();
    container.registerInstance(isEmbeddedModeToken, isEmbeddedMode);

    // ProductIdManager
    container.register(ProductIdFromSdkProvider, {
        useFactory: instanceCachingFactory(() => {
            return new ProductIdFromSdkProvider();
        }),
    });
    container.register(globalProductIdManagerToken, {
        useFactory: instanceCachingFactory(() => {
            const productIdManager = new ProductIdManager();
            if (isEmbeddedMode) {
                productIdManager.registerProvider(
                    new ProductIdFromAdditionalInfoProvider()
                );
            } else {
                productIdManager.registerProvider(
                    container.resolve(ProductIdFromSdkProvider)
                );
            }
            return productIdManager;
        }),
    });

    // ProductVariantsIdManager
    container.register(ProductVariantIdFromSdkProvider, {
        useFactory: instanceCachingFactory(() => {
            return new ProductVariantIdFromSdkProvider();
        }),
    });

    container.register(globalProductVariantsIdManagerToken, {
        useFactory: instanceCachingFactory(() => {
            const productVariantsIdManager = new ProductVariantsIdManager();
            if (isEmbeddedMode) {
                productVariantsIdManager.registerProvider(
                    new ProductVariantIdFromAdditionalInfoProvider()
                );
                productVariantsIdManager.registerProvider(
                    new ProductVariantIdFromUrlProvider()
                );
            } else {
                productVariantsIdManager.registerProvider(
                    container.resolve(ProductVariantIdFromSdkProvider)
                );
            }
            return productVariantsIdManager;
        }),
    });

    container.register(SdkModule, {
        useFactory: instanceCachingFactory(() => new SdkModule(initFromSdk)),
    });
    container.resolve(SdkModule).registerProviders();

    if (isEmbeddedMode) {
        void initFromAdditionalInfo();
    }
}

__gwMain();
