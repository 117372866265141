import AuthenticationSdkReadySdkEvent from "@modules/sdk/common_sdk/events/AuthenticationSdkReadySdkEvent";
import { dispatchSdkEvent } from "@modules/sdk/helpers";
import SdkMethodsCollector from "@modules/sdk/methods/SdkMethodsCollector";
import Sdk from "@modules/sdk/Sdk";
import { SdkRegisteredModuleInterface } from "@modules/sdk/SdkRegisteredModuleInterface";
import { sdkMethodsCollectorToken } from "@modules/sdk/tokens";
import { inject, singleton } from "tsyringe";

import AuthenticationSdkMethods from "./AuthenticationSdkMethods";

@singleton()
class AuthenticationSdk implements SdkRegisteredModuleInterface {
    public readonly methods: AuthenticationSdkMethods;

    constructor(
        @inject(sdkMethodsCollectorToken)
        private readonly _collector: SdkMethodsCollector,
        private readonly authenticationsSdkMethods: AuthenticationSdkMethods
    ) {
        this.methods = this.authenticationsSdkMethods;
    }

    public register(sdk: Sdk & { authentication?: AuthenticationSdk }) {
        sdk.authentication = this;
        dispatchSdkEvent(new AuthenticationSdkReadySdkEvent());
    }
}

export default AuthenticationSdk;
