import { CURRENT_APP_QUERY_PARAMETER } from "@/constants/current_app";
import { globalLoggerToken, gwStorefrontAppInfoToken } from "@/tokens";
import ApiClient from "@lib/ApiClient";
import AuthApiClient from "@lib/ApiClient/AuthApiClient";
import { TokenManagerModule } from "@modules/token_manager/TokenManagerModule";
import {
    DependencyContainer,
    container,
    instanceCachingFactory,
    singleton,
} from "tsyringe";

import { ModuleInterface } from "@interfaces/ModuleInterface";

import AnalyticsEventsCollectorInterface from "./AnalyticsEventsCollector/AnalyticsEventsCollectorInterface";
import { AnalyticsEventsFactory } from "./AnalyticsEventsCollector/AnalyticsEventsFactory";
import RxJSAnalyticsEventsCollector from "./AnalyticsEventsCollector/RxJSAnalyticsEventsCollector";
import AnalyticsEventsSender from "./AnalyticsEventsSender/AnalyticsEventsSender";
import {
    eventsCollectorAuthApiClientToken,
    eventsCollectorToken,
} from "./di_tokens";
import MetricsCollectorApiService from "./MetricsCollectorApiService";

@singleton()
export class AnalyticsEventsCollectorModule
    implements
        ModuleInterface<{
            eventsFactory: AnalyticsEventsFactory;
            eventsCollector: AnalyticsEventsCollectorInterface;
        }>
{
    exports: {
        eventsFactory: AnalyticsEventsFactory;
        eventsCollector: AnalyticsEventsCollectorInterface;
    };
    public constructor(private tokenManagerModule: TokenManagerModule) {
        const eventsCollectorModuleLogger = container
            .resolve(globalLoggerToken)
            .newLogger("EventsCollectorModule");
        container.register(eventsCollectorAuthApiClientToken, {
            useFactory: instanceCachingFactory((di) => {
                return new AuthApiClient(
                    new ApiClient(
                        `${
                            di.resolve(gwStorefrontAppInfoToken).appProxyPrefix
                        }/events-collector-service/v2/`,
                        undefined,
                        {
                            [CURRENT_APP_QUERY_PARAMETER]: di.resolve(
                                gwStorefrontAppInfoToken
                            ).currentApp,
                        },
                        eventsCollectorModuleLogger
                    ),
                    this.tokenManagerModule.exports.tokenManager
                );
            }),
        });
        const eventsFactory = new AnalyticsEventsFactory(
            window.performance,
            navigator.userAgent,
            location.href,
            __APP_NAME__
        );
        const eventsCollector = new RxJSAnalyticsEventsCollector();
        container.registerInstance(eventsCollectorToken, eventsCollector);
        container.registerInstance(AnalyticsEventsFactory, eventsFactory);

        const eventsSender = new AnalyticsEventsSender(
            eventsCollector,
            new MetricsCollectorApiService(
                container.resolve(eventsCollectorAuthApiClientToken),
                eventsCollectorModuleLogger
            )
        );

        eventsSender.start();

        this.exports = {
            eventsFactory,
            eventsCollector,
        };
    }

    registerProviders(di: DependencyContainer) {
        return {};
    }
}
