import { ProductIdFromSdkProvider } from "@lib/ProductIdManager/ProductIdFromSdkProvider";
import { ProductVariantIdFromSdkProvider } from "@lib/ProductVariantIdManager/ProductVariantIdFromSdkProvider";
import { AnalyticsEventsCollectorModule } from "@modules/analytics_events_collector/AnalyticsEventsCollectorModule";
import { container, instanceCachingFactory, singleton } from "tsyringe";

import { ModuleInterface } from "@interfaces/ModuleInterface";

import SdkAnalyticsEventsFactory from "./analytics/SdkAnalyticsEventsFactory";
import CommonSdk from "./common_sdk/CommonSdk";
import { COMMON_SDK_NAME } from "./common_sdk/constants";
import { SDK_READY } from "./constants";
import SdkMethodsCollector from "./methods/SdkMethodsCollector";
import Sdk from "./Sdk";
import {
    sdkAnalyticsEventsFactoryToken,
    sdkMethodsCollectorToken,
    sdkToken,
} from "./tokens";
import { SdkInitOptions } from "./types";

@singleton()
export class SdkModule
    implements
        ModuleInterface<{
            sdk: Sdk;
        }>
{
    exports: {
        sdk: Sdk;
    };

    public constructor(initApp?: (options: SdkInitOptions) => void) {
        container.register(sdkAnalyticsEventsFactoryToken, {
            useFactory: instanceCachingFactory(() => {
                return new SdkAnalyticsEventsFactory(
                    navigator.userAgent,
                    location.href,
                    __APP_NAME__
                );
            }),
        });

        container.register(sdkToken, {
            useFactory: instanceCachingFactory(() => {
                return new Sdk(
                    container.resolve(AnalyticsEventsCollectorModule),
                    container.resolve(sdkAnalyticsEventsFactoryToken),
                    initApp
                );
            }),
        });

        container.register(sdkMethodsCollectorToken, {
            useFactory: instanceCachingFactory(
                () =>
                    new SdkMethodsCollector(
                        container.resolve(
                            AnalyticsEventsCollectorModule
                        ).exports.eventsCollector,
                        container.resolve(sdkAnalyticsEventsFactoryToken)
                    )
            ),
        });

        container
            .resolve(sdkToken)
            .registerModule(
                COMMON_SDK_NAME,
                new CommonSdk(
                    container.resolve(ProductIdFromSdkProvider),
                    container.resolve(ProductVariantIdFromSdkProvider)
                )
            );

        const sdk = container.resolve(sdkToken);

        if (!window.gw) {
            window.gw = {};
        }

        if (!window.gw.sdk) {
            window.gw.sdk = sdk;
            container
                .resolve(AnalyticsEventsCollectorModule)
                .exports.eventsCollector.pushEvent(
                    container
                        .resolve(sdkAnalyticsEventsFactoryToken)
                        .createSdkStarted()
                );
        }

        // This timeout is needed to ensure that SDK object correctly registered in DiContainer, before SDK_READY event is dispatched
        // Without this timeout, handlers for SDK_READY will be executed synchronously, before SDK object is registered
        setTimeout(() => {
            document.dispatchEvent(new Event(SDK_READY));
        }, 0);

        this.exports = {
            sdk: container.resolve(sdkToken),
        };
    }
    registerProviders() {
        return {};
    }
}
