import { COMMON_SDK_EVENTS } from "./constants";

class CommonSdkEvents {
    public readonly WIDGET_CLOSED = COMMON_SDK_EVENTS.WIDGET_CLOSED;
    public readonly WIDGET_INJECTED = COMMON_SDK_EVENTS.WIDGET_INJECTED;
    public readonly WIDGET_OPENED = COMMON_SDK_EVENTS.WIDGET_OPENED;
    public readonly AUTHENTICATION_SDK_READY =
        COMMON_SDK_EVENTS.AUTHENTICATION_SDK_READY;
    public readonly REVIEWS_SDK_READY = COMMON_SDK_EVENTS.REVIEWS_SDK_READY;
    public readonly REWARDS_SDK_READY = COMMON_SDK_EVENTS.REWARDS_SDK_READY;
    public readonly WISHLISTS_SDK_READY = COMMON_SDK_EVENTS.WISHLISTS_SDK_READY;

    // DEPRECATED
    public readonly [COMMON_SDK_EVENTS.WIDGET_CLOSED] =
        COMMON_SDK_EVENTS.WIDGET_CLOSED;
    public readonly [COMMON_SDK_EVENTS.WIDGET_INJECTED] =
        COMMON_SDK_EVENTS.WIDGET_INJECTED;
    public readonly [COMMON_SDK_EVENTS.WIDGET_OPENED] =
        COMMON_SDK_EVENTS.WIDGET_OPENED;
    public readonly [COMMON_SDK_EVENTS.REVIEWS_SDK_READY] =
        COMMON_SDK_EVENTS.REVIEWS_SDK_READY;
    public readonly [COMMON_SDK_EVENTS.REWARDS_SDK_READY] =
        COMMON_SDK_EVENTS.REWARDS_SDK_READY;
    public readonly [COMMON_SDK_EVENTS.WISHLISTS_SDK_READY] =
        COMMON_SDK_EVENTS.WISHLISTS_SDK_READY;
}

export default CommonSdkEvents;
