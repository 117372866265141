import { ERROR_CODES } from "../constants";

class SdkError extends Error {
    public readonly code: ERROR_CODES;
    public readonly message: string;

    constructor(code: ERROR_CODES, message = "") {
        super();
        this.code = code;
        this.message = message;
    }
}

export default SdkError;
