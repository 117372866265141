import { CURRENT_APP_QUERY_PARAMETER } from "@/constants/current_app";
import { ModuleInterface } from "@/interfaces/ModuleInterface";
import {
    globalLoggerToken,
    gwStorefrontAppInfoToken,
    localStorageToken,
} from "@/tokens";
import ApiClient from "@lib/ApiClient";
import { tokenManagerToken } from "@modules/authentication/di_tokens";
import {
    DependencyContainer,
    container,
    instanceCachingFactory,
    singleton,
} from "tsyringe";

import { RefreshTokenApiService } from "./api_services/RefreshTokenApiService";
import { refreshTokenServiceApiClientToken } from "./di_tokens";
import GrowaveTokenManager from "./TokenManager/GrowaveTokenManager";
import TokenManagerInterface from "./TokenManager/TokenManagerInterface";

@singleton()
export class TokenManagerModule
    implements ModuleInterface<{ tokenManager: TokenManagerInterface }>
{
    exports: { tokenManager: TokenManagerInterface };
    public constructor() {
        container.registerInstance(
            refreshTokenServiceApiClientToken,
            new ApiClient(
                `${
                    container.resolve(gwStorefrontAppInfoToken).appProxyPrefix
                }/storefront-api/storefront-authentication-service/v2`,
                undefined,
                {
                    [CURRENT_APP_QUERY_PARAMETER]: container.resolve(
                        gwStorefrontAppInfoToken
                    ).currentApp,
                },
                container.resolve(globalLoggerToken)
            )
        );
        container.register(tokenManagerToken, {
            useFactory: instanceCachingFactory((di) => {
                return new GrowaveTokenManager(
                    container.resolve(RefreshTokenApiService),
                    container.resolve(localStorageToken),
                    container.resolve(gwStorefrontAppInfoToken).tokenStorageKey,
                    0,
                    container.resolve(globalLoggerToken)
                );
            }),
        });
        this.exports = {
            tokenManager: container.resolve(tokenManagerToken),
        };
    }
    registerProviders(di: DependencyContainer) {
        return {};
    }
}
